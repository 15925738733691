import React from 'react';
import { TransformedData, baseClass } from '../comparison-group-result-table-column';
import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import { formatIndicatorValue } from 'client/shared/core/performance-data';
import { Tooltip } from 'client/shared/components/tooltip';

interface PreviousDataPointProps {
  readonly data: TransformedData;
}

export const PreviousDataPoint: React.FC<PreviousDataPointProps> = (props) => {
  const recentDatum = props.data.recentDatum;
  const fipsMatch = props.data.id === recentDatum?.fipsArea.id;
  if (recentDatum?.value) {
    return (
      <div
        className={`${baseClass}-indicator-value-wrapper w-100 d-flex align-items-center justify-content-end`}
      >
        <div
          className={`${baseClass}-indicator-value font-weight-bold mr-2 text-liberty`}
        >
          {formatIndicatorValue(recentDatum.value, props.data.variableValueType)}
        </div>
        <Tooltip
          anchorClassName={`${baseClass}-indicator-fips-tooltip font-size-sm`}
          content={`Data from ${recentDatum.fipsArea.name}`}
          disable={fipsMatch}
          id={`table-${props.data.variableName}-${props.data.id}-fips-mismatch`}
        >
          <MaterialIcon
            ariaLabel={`Data from ${recentDatum.fipsArea.name}`}
            className={`align-middle ${fipsMatch ? 'invisible' : 'visible'}`}
            icon={MaterialIconName.INFO_OUTLINE}
            style={{ fontSize: '20px' }}
          />
        </Tooltip>
      </div>
    );
  }
  return <div className="d-flex justify-content-center w-100">--</div>;
};

PreviousDataPoint.displayName = 'PreviousDataPoint';
