import { StatisticType, DateLevel } from '@polco-us/types';
import { TrackVariable } from 'core';
import _, { floor, range } from 'lodash';
import React from 'react';
import { ScaleSpec } from '@nivo/scales';
import { DomainIndicatorsFipsAreaRow } from '../domain-indicators-group-wrapper';

export function useLineChartScale(args: {
  readonly data: readonly DomainIndicatorsFipsAreaRow[];
  readonly goalLine?: number;
  readonly forecastEnabled?: boolean;
  readonly variable: Pick<
    TrackVariable,
    'id' | 'name' | 'valueType' | 'direction' | 'dateLevel' | 'statisticType'
  >;
  readonly isSmallBreakpoint: boolean;
}) {
  const { data, goalLine, variable, forecastEnabled, isSmallBreakpoint } = args;
  return React.useMemo<{
    readonly yAxisValues: number[];
    readonly yAxisScaleProps: ScaleSpec;
    readonly xAxisValues: number[] | Date[];
    readonly xAxisScaleProps: ScaleSpec;
  }>(() => {
    const allData = _(data)
      .flatMap((d) => [
        d.trackAreaData.performanceData,
        forecastEnabled ? d.trackAreaData.arPredictiveData : [],
        forecastEnabled ? d.trackAreaData.gpalPredictiveData : [],
      ])
      .flatten()
      .value();

    const valuesWithGoal = allData.map((d) => d.value).concat(goalLine ?? []);
    const minY =
      variable.statisticType === StatisticType.INDEX
        ? 0
        : _.min(valuesWithGoal) ?? 0;
    const maxY =
      variable.statisticType === StatisticType.INDEX
        ? 90
        : _.max(valuesWithGoal) ?? 100;
    const yValues = getYAxisValues(minY, maxY);
    const yScale: ScaleSpec = {
      type: 'linear',
      min: Math.min(...yValues),
      max: Math.max(...yValues),
    };

    const minX = _(allData)
      .map(({ recordedAt }) => recordedAt)
      .min();
    const maxX = _(allData)
      .map(({ recordedAt }) => recordedAt)
      .max();
    const xScale: ScaleSpec =
      variable.dateLevel === DateLevel.YEAR
        ? {
            type: 'linear',
            min: minX?.getUTCFullYear() ?? new Date().getUTCFullYear() - 1,
            max: maxX?.getUTCFullYear() ?? new Date().getUTCFullYear(),
          }
        : { type: 'time', min: minX, max: maxX };
    const xValues = getXAxisValuesByDateLevel(
      minX,
      maxX,
      variable.dateLevel,
      isSmallBreakpoint
    );

    return {
      yAxisValues: yValues,
      yAxisScaleProps: yScale,
      xAxisValues: xValues,
      xAxisScaleProps: xScale,
    };
  }, [variable, data, goalLine, forecastEnabled, isSmallBreakpoint]);
}

function getYAxisValues(minY: number, maxY: number) {
  if (minY === maxY) {
    return _.uniq([
      Math.floor(maxY - maxY * 0.05),
      Math.round(maxY),
      Math.ceil(maxY + maxY * 0.05),
    ]);
  }
  if (maxY - minY <= 5) {
    return range(floor(minY), maxY + 1, 0.5);
  }
  const rangeMin = _.floor(minY / 10) * 10;
  const rangeMax = _.floor((maxY + 10) / 10) * 10;

  const rangeLength = rangeMax - rangeMin;
  const modNumber =
    rangeLength % 10 ? _.floor(rangeLength / 10) + 1 : _.floor(rangeLength / 10);
  const rangeValues = range(rangeMin, rangeMax, modNumber);
  return _.uniq([
    rangeValues[0] - modNumber < 0 ? 0 : rangeValues[0] - modNumber,
    ...rangeValues,
    rangeValues[rangeValues.length - 1] + modNumber,
  ]);
}

function getXAxisValuesByDateLevel(
  minX: Date | undefined,
  maxX: Date | undefined,
  dateLevel: DateLevel,
  isSmallBreakpoint: boolean
) {
  if (!minX || !maxX) {
    return [];
  }
  const yearDiff = maxX.getUTCFullYear() + 1 - minX.getUTCFullYear();
  const step = isSmallBreakpoint && yearDiff > 5 ? 3 : 1;
  const years = range(maxX.getUTCFullYear(), minX.getUTCFullYear() - 1, -step).map(
    (v) => new Date(`01/01/${v}`)
  );
  const modNumber = _.floor(years.length / 10) * 2;
  if (modNumber > 0) {
    const tenYearsRange = range(
      maxX.getUTCFullYear(),
      minX.getUTCFullYear() - 1,
      -modNumber
    ).map((v) => new Date(`01/01/${v}`));
    switch (dateLevel) {
      case DateLevel.MONTH:
        return tenYearsRange;
      case DateLevel.YEAR:
        return tenYearsRange.map((v) => v.getUTCFullYear());
    }
  }

  switch (dateLevel) {
    case DateLevel.MONTH: {
      const yearRange = range(maxX.getUTCFullYear(), minX.getUTCFullYear() - 1, -1);
      const quarterMonths = yearRange.flatMap((y) => {
        const jan = new Date(`01/01/${y}`);
        const apr = new Date(`04/01/${y}`);
        const jul = new Date(`07/01/${y}`);
        const oct = new Date(`10/01/${y}`);
        return [jan, apr, jul, oct];
      });
      if (quarterMonths.length > 16) {
        return years;
      }
      return quarterMonths;
    }
    case DateLevel.YEAR:
      return years.map((v) => v.getUTCFullYear());
  }
}
